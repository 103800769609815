import React, { useState, useEffect }  from 'react';
import {Link} from 'react-router-dom';
import Header from '../inc/Header';
import Footer from '../inc/Footer';

import Head from '../inc/Head';


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import ReactStars from "react-rating-stars-component";

import $ from 'jquery';

import ImageCropModal from './ImageCropModal';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import FooterReview from '../inc/FooterReview';

import Spinner from "../inc/Spinner";

const customarsay = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function PostReview() {

   let navigate=useNavigate();

   const [loader, setLoader] = useState(false);

   let [rating,setRating]=useState("");
   let [name,setName]=useState("");
   let [description,setDescription]=useState("");
   // let [photo,setPhoto]=useState(null);

   const logocss={
      width: '80%'
   };

      const [loading, setLoading] = React.useState(true);

      function getPreloader() {
         // Simulating data fetch
         const timer = setTimeout(() => setLoading(false), 1000);
         return () => clearTimeout(timer); // Cleanup timer
      }

      const ratingChanged = (newRating) => {
         console.log(newRating);
         setRating(newRating);
         $(".ratingstar").removeClass('border-danger').addClass('border-secondary');
      };

      async function submitreview() {
         var flag=1;

         if (rating=='') {
            $(".ratingstar").removeClass('border-secondary').addClass('border-danger');
            flag=0;
         } else {
            $(".ratingstar").addClass('border-secondary').removeClass('border-danger');
         }

         if (name=='') {
            $(".name").removeClass('border-secondary').addClass('border-danger');
            flag=0;
         } else {
            $(".name").addClass('border-secondary').removeClass('border-danger');
         }

         if (description=='') {
            $(".description").removeClass('border-secondary').addClass('border-danger');
            flag=0;
         } else {
            $(".description").addClass('border-secondary').removeClass('border-danger');
         }

         // if (croppedImage==null) {
         //    $(".photo").removeClass('border-secondary').addClass('border-danger');
         //    flag=0;
         // } else {
         //    $(".photo").addClass('border-secondary').removeClass('border-danger');
         // }

         if (flag==1) {
            try {
               setLoader(true);
               const blob = await fetch(croppedImage).then((res) => res.blob()); // Convert URL to Blob
               const formData = new FormData();
               formData.append('image', new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' }));
               formData.append('name',name);
               formData.append('description',description);
               formData.append('rating',rating);
               formData.append('croppedImage',croppedImage);
           
               // Send the image to the server
               const response = await axios.post('https://vasundharahotel.com/boss/api/uploadImagePostReview', formData, {
                 headers: { 'Content-Type': 'multipart/form-data' },
               });

               setLoader(false);
           
               // console.log('Uploaded image URL:', response.data.url);
               // console.log("Review_id",response.data.review_id);
               // alert('Image uploaded successfully!');

               var path=`/rating/myreview/`+btoa(response.data.review_id);
               navigate(path);

             } catch (error) {
               console.error('Error uploading the image:', error);
               alert('Failed to upload the image.');
             }
         }
      }

      // CROPPING IMAGE
      const [imageSrc, setImageSrc] = useState(null);
      const [open, setOpen] = useState(false);
      const [croppedImage, setCroppedImage] = useState(null);

      const handleFileChange = (e) => {
         const file = e.target.files[0];
         const reader = new FileReader();
         reader.onload = () => {
            setImageSrc(reader.result);
            setOpen(true);
         };
         reader.readAsDataURL(file);
      };

      const handleCropComplete = async (croppedImage) => {
         setCroppedImage(croppedImage);
      };
      // CROPPING IMAGE
      

      useEffect(() => {
         getPreloader();
      },[]);
      return (
      <>
      <Head />

      {loading ? 
      <div className="preloader"> 
          <div className="container">
            <div className="dot dot-1"></div>
            <div className="dot dot-2"></div>
            <div className="dot dot-3"></div>
          </div>
      </div>
      :

      

      <div>

        <header className="one">
        <div className="bottom-bar ">
        <div className="container">
              <div className="row align-items-center">
                  <div className="col-xl-12 text-center pt-3">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                          {/* <Link to="/"> */}
                              <img alt="logo" style={logocss} src="assets/img/logo.png" />
                          {/* </Link> */}
                        </div>
                        
                    </div>
                  </div>
                  
              </div>
        </div>  
        </div>
        

       
      </header>


<section class="gap no-top pt-3" style={{padding: '20px 0'}}>
   <div class="container">
      <div class="heading-two" style={{textAlign: 'center'}}>
         <h4>Submit Your Review</h4>
         <div class="line"></div>
      </div>
      <div class="row">
         <div class="col-xl-12 col-lg-12 pt-1" style={{textAlign: 'center'}}>
            <div class="chef_">
               {/* <img alt="cook chef" src="assets/img/chef-1.png" /> */}
               {/* <div class="chef-text"> */}
                  {/* <div>
                     <span>Dessert Specialist</span>
                     <h3>Somnath Dutta</h3>
                  </div> */}
                    <div className='ratingstar align-items-center justify-content-between__ border-secondary_' style={{textAlign: 'center',border: '1px solid rgba(227, 227, 227, 0.9)'}}>
                    <ReactStars
                         style={{textAlign: 'center'}}
                        count={5}
                        onChange={ratingChanged}
                        size={70}
                        // isHalf={true}
                        isHalf={false}
                        emptyIcon={<i className="far fa-star"></i>}
                        // halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        color="#949191"
                        activeColor="#ffd700"
                        label="Rating"
                    />
                     {/* (Under Processing) */}
                    </div>

               {/* </div> */}
            </div>
         </div>

         <div class="col-xl-12 col-lg-12 py-4">
            <input type='text' className='name form-control form-control-lg' placeholder='Your Name' onChange={(e)=>{
               setName(e.target.value);
               $(".name").addClass('border-secondary').removeClass('border-danger');
            }}/>
         </div>

         <div class="col-xl-12 col-lg-12 pb-3">
            <textarea className='description form-control form-control-lg' rows="3" placeholder='Your Review Text' onChange={(e)=>{
               setDescription(e.target.value);
               $(".description").addClass('border-secondary').removeClass('border-danger');
            }}/>
         </div>

         <div class="col-xl-12 col-lg-12 pb-3">
            <label className='small py-2'>Upload Photo (optional)</label>
            <input type='file' id="image" className='photo form-control' accept="image/*" onChange={handleFileChange}/>
            {croppedImage && <img src={croppedImage} alt="Cropped" style={{ marginTop: 20, width: '100%' }} />}
            <ImageCropModal
            open={open}
            onClose={() => setOpen(false)}
            imageSrc={imageSrc}
            onCropComplete={handleCropComplete}
            />
         </div>
         
         {loader && <Spinner />}
         <div class="col-xl-12 col-lg-12 pt-3" style={{textAlign: 'center'}}>
            <input type='button' className='btn btn-lg btn-success' value="Post Review" onClick={submitreview}/>
         </div>

      </div>
   </div>
</section>


 


        <FooterReview />

        </div>
}
      </>
    );
  }
  
  export default PostReview;
  