import * as React from 'react';
import { useState, useEffect }  from 'react';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

let hour ="";
var welcomemessage="";

export default function HomePageWelcome() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isMobile, setIsMobile] = useState(false);

  function getWelcomeMessage() {

    // localStorage.clear();

    var hours = 24; // Reset when storage is more than 24hours
    var now = new Date().getTime();
    var setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {

        localStorage.setItem('setupTime', now);
        localStorage.setItem('setupTimeCount',1);

        // setOpen(true);
        const d = new Date();
        hour = d.getHours();
    
        if (hour>=0 && hour<=4) {
          welcomemessage="~~~Good Night~~~";
        } else if (hour>=5 && hour<=11) {
          welcomemessage="~~~Good Morning~~~";
        } else if (hour>=12 && hour<=13) {
          welcomemessage="~~~Good Noon~~~";
        } else if (hour>=14 && hour<=16) {
          welcomemessage="~~~Good Afternoon~~~";
        } else if (hour>=17 && hour<=19) {
          welcomemessage="~~~Good Evening~~~";
        } else if (hour>=20 && hour<=23) {
          welcomemessage="~~~Good Night~~~";
        }

        setOpen(true);
        // setTimeout(()=>{
        //   setOpen(true);
        // }, 4000);

        setTimeout(()=>{
          setOpen(false);
        }, 5000);

    } else {

        if(now-setupTime > hours*60*60*1000) {
            localStorage.clear();
            localStorage.setItem('setupTime', now);
            localStorage.setItem('setupTimeCount',1);

            
            const d = new Date();
            hour = d.getHours();
        
            if (hour>=0 && hour<=4) {
              welcomemessage="~~~Good Night~~~";
            } else if (hour>=5 && hour<=11) {
              welcomemessage="~~~Good Morning~~~";
            } else if (hour>=12 && hour<=13) {
              welcomemessage="~~~Good Noon~~~";
            } else if (hour>=14 && hour<=16) {
              welcomemessage="~~~Good Afternoon~~~";
            } else if (hour>=17 && hour<=19) {
              welcomemessage="~~~Good Evening~~~";
            } else if (hour>=20 && hour<=23) {
              welcomemessage="~~~Good Night~~~";
            }

            // setTimeout(()=>{
            //   setOpen(true);
            // }, 4000);
            setOpen(true);

            setTimeout(()=>{
              setOpen(false);
            }, 10000);
            

        } else {

          if (localStorage.getItem('setupTimeCount')<=2) {

              localStorage.setItem('setupTimeCount',parseInt(localStorage.getItem('setupTimeCount'))+1);

              
              const d = new Date();
              hour = d.getHours();
          
              if (hour>=0 && hour<=4) {
                welcomemessage="~~~Good Night~~~";
              } else if (hour>=5 && hour<=11) {
                welcomemessage="~~~Good Morning~~~";
              } else if (hour>=12 && hour<=13) {
                welcomemessage="~~~Good Noon~~~";
              } else if (hour>=14 && hour<=16) {
                welcomemessage="~~~Good Afternoon~~~";
              } else if (hour>=17 && hour<=19) {
                welcomemessage="~~~Good Evening~~~";
              } else if (hour>=20 && hour<=23) {
                welcomemessage="~~~Good Night~~~";
              }

              // setTimeout(()=>{
              //   setOpen(true);
              // }, 4000);

              setOpen(true);
  
              setTimeout(()=>{
                setOpen(false);
              }, 10000);

          }
        
        }

    }


    // console.log("=====>>>=======>",localStorage.getItem('setupTimeCount'));

  }

  useEffect(()=>{
    
    getWelcomeMessage();

    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleResize = () => setIsMobile(mediaQuery.matches);

    handleResize(); // Initial check
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);

  },[])

  const styles = {
    position: 'absolute',
    top: isMobile ? '40%': '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '80%':'30%',
    bgcolor: 'background.paper',
    border: '2px solid #ff183b',
    boxShadow: 24,
    p: 4,
    borderRadius: '20px',
    background: 'rgb(255, 235, 238)'
  };

  return (
    
    <>
    <Container>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title modal-sm"
            aria-describedby="modal-modal-description"
            
        >
            {/* <Box sx={styles}> */}
            <Box sx={styles}>
                {/* <Typography className="text-red" spacing={2} textAlign="center" sx={{ mt: 1, mb: 2, alignContent: 'center', justifyContent: 'center', fontSize: '16px' }}>
                    {inactivemsg}
                </Typography> */}

                <Typography className="text-deepblue" spacing={2} textAlign="center" sx={{ mt: 1, mb: 2, alignContent: 'center', justifyContent: 'center', fontSize: '1.1rem', fontWeight: 700 }}>
                    <img src="assets/img/welcome.png" width="100%"/>
                </Typography>
                <Typography className="text-deepblue" spacing={2} textAlign="center" sx={{ mt: 1, mb: 2, alignContent: 'center', justifyContent: 'center' }}>
                    <Typography sx={{color: '#0173ee', fontSize: '1.25rem', fontWeight: 700}}>{welcomemessage}</Typography>
                </Typography>

                {/* <Typography className="text-maroon" textAlign="center" sx={{ mt: 1, mb: 2, alignContent: 'center', justifyContent: 'center', fontSize: '16px' }}>
                    <Button variant="contained" sx={{ mt: 2, mb: 2, fontSize: '0.8rem' }} color='red' onClick={handleClose}>Close</Button>  
                </Typography> */}
                {/* <Grid Container mt={2} textAlign="center" className='btnRow'>
                    <Button variant="contained" color='info' onClick={handleClose}>Close</Button>  
                </Grid>      */}
            </Box>
        </Modal>
        </Container>
    </>
    
  );
}

