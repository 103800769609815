import React, { useState, useEffect }  from 'react';
import {Link} from 'react-router-dom';
import Header from '../inc/Header';
import Footer from '../inc/Footer';

import Head from '../inc/Head';


function Contact() {

   const [loading, setLoading] = React.useState(true);

   function getPreloader() {
      // Simulating data fetch
      const timer = setTimeout(() => setLoading(false), 1000);
      return () => clearTimeout(timer); // Cleanup timer
   }


   let [webconfig, setWebconfig] = React.useState("");
   async function getWebconfig() {
      fetch('https://vasundharahotel.com/boss/api/webconfig_api')
      .then((res) => {
        return res.json();
      })
      .then((data) => {
      //   console.log("====>>>=============>",data.webconfig);
        setWebconfig(data.webconfig);
      });

      // console.log("====>>>=============>",webconfig.phone)
   }


   useEffect(() => {

      getPreloader();

      getWebconfig();
   },[]);

    return (
      <>

      <Head />

      {loading ? 
      <div className="preloader"> 
          <div className="container">
            <div className="dot dot-1"></div>
            <div className="dot dot-2"></div>
            <div className="dot dot-3"></div>
          </div>
      </div>
      :

      <div>
<Header />
<section class="banner" style={{backgroundImage: "url(assets/img/background.png)"}}>
   <div class="container">
      <div class="row align-items-center">
         <div class="col-lg-7">
            <div class="title-area-data">
            <h2>Contact Us</h2>
            <p>A magical combination that sent aromas to the taste buds</p>
            </div>
            {/* <ol class="breadcrumb">
               <li class="breadcrumb-item">
                 <Link to="/"><i class="fa-solid fa-house"></i> Home</Link>
               </li>
               <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
            </ol> */}
         </div>
         <div class="col-lg-5">
            <div class="row">
               <div class="col-6">
                  <div class="title-area-img">
                     <img alt="title-area-img" src="assets/img/title-area-img-1.jpg" />
                     <img alt="pata" class="pata" src="assets/img/pata.png" />
                  </div>
               </div>
               <div class="col-6">
                  <div class="title-area-img two">
                     <img alt="title-area-img" src="assets/img/title-area-img-2.jpg" />
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>

<ol class="breadcrumb" style={{marginLeft: '10px'}}>
   <li class="breadcrumb-item">
      <Link to="/"><i class="fa-solid fa-house"></i> Home</Link>
   </li>
   {/* <li class="breadcrumb-item active" aria-current="page">Menu</li> */}
   <li class="breadcrumb-item text-white" aria-current="page">Contact</li>
</ol>

<section class="gap"> 
   <div class="container">
      <div class="row">
         <div class="col-xl-7">
            <div class="get-in-touch">
               <h2>Get in Touch</h2>
               <ul class="booking">
               <li class="contact">
                  <i>
                     <svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_16-Smartphone" data-name="16-Smartphone"><path d="m23 2h-14a3 3 0 0 0 -3 3v22a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-22a3 3 0 0 0 -3-3zm-5.39 2-.33 1h-2.56l-.33-1zm6.39 23a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1-1v-22a1 1 0 0 1 1-1h3.28l.54 1.63a2 2 0 0 0 1.9 1.37h2.56a2 2 0 0 0 1.9-1.37l.54-1.63h3.28a1 1 0 0 1 1 1z"/><path d="m17 24h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2z"/></g></svg>
                  </i>
                  <div>
                     <span>for booking</span>
                     <a href={"tel:+91-"+webconfig.phone}><span>+91-{webconfig.phone}</span></a>
                     <a href={"tel:+91-7044776584"}><span>+91-7044776584</span></a>
                  </div>
               </li>
               <li class="contact">
                  <i>
                     <svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_01-Email" data-name="01-Email"><path d="m29.61 12.21-13-10a1 1 0 0 0 -1.22 0l-13 10a1 1 0 0 0 -.39.79v14a3 3 0 0 0 3 3h22a3 3 0 0 0 3-3v-14a1 1 0 0 0 -.39-.79zm-13.61-7.95 11.36 8.74-11.36 8.74-11.36-8.74zm11 23.74h-22a1 1 0 0 1 -1-1v-12l11.39 8.76a1 1 0 0 0 1.22 0l11.39-8.76v12a1 1 0 0 1 -1 1z"></path></g></svg>
                  </i>
                  <div>
                     <span>for private dining</span>
                     <a href={"mailto:"+webconfig.email}><span className='text-lowercase'>{webconfig.email}</span></a>
                  </div>
               </li>
               </ul>
            </div>
            <div class="mapouter">
               <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14704.285707711544!2d88.4366982!3d22.8738197!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f897001f45f23b%3A0x514c0906a8de8083!2sVASUNDHARA%20FAMILY%20RESTAURANT!5e0!3m2!1sen!2sin!4v1731924441412!5m2!1sen!2sin" width="600" height="450" style= {{ border: '0'}}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
            <div class="align-items-center d-flex mt-3">
               <i class="fa-solid fa-location-dot me-3"></i>
               <p><span className='h5 fw-bold'>{webconfig.company_name}</span><br/>{webconfig.address_line1}, {webconfig.address_line2}, {webconfig.city}, {webconfig.pincode}, INDIA</p>
            </div>
            {/* <div class="mt-2 d-flex align-items-center">
               <i class="fa-solid fa-location-dot me-3"></i>
               <p>Belfast BT4 3LP Sea Beach United State</p>
            </div> */}
         </div>
         <div class="col-xl-5">
            <div class="get-in-touch">
               <h2>Have Question?</h2>
               <p>For all enquires, please contact us and one of our delightful team will be be happy to help.</p>
            </div>
            
            <form role="form"  id="contact-form" method="post" class="add-review leave-comment mt-4">
               <input type="text" name="complete_name" id="Complete_Name" placeholder="Full Name" required />
                <input type="text" name="email_address" placeholder="Email Address" id="email_address" required />
               <input type="text"  name="phone" placeholder="Phone No" />
               <textarea placeholder="Message" name="message"></textarea>
               <div className='text-center'>
               <button class="button" type="submit" value="submit">
                  <span>send Message</span>
               </button>
               </div>
             </form>
         </div>
      </div>
   </div>
</section>

        <Footer />
        </div>
}
      </>
    );
  }
  
  export default Contact;
  