import React, { useState, useEffect }  from 'react';
import {Link} from 'react-router-dom';
import Header from '../inc/Header';
import Footer from '../inc/Footer';

import Head from '../inc/Head';


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import ReactStars from "react-rating-stars-component";

const customarsay = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function Review() {

   const [loading, setLoading] = React.useState(true);

   function getPreloader() {
      // Simulating data fetch
      const timer = setTimeout(() => setLoading(false), 1000);
      return () => clearTimeout(timer); // Cleanup timer
   }

   let [reviewlist,setReviewlist]=useState([]);
   async function getAllReview() {
      fetch('https://vasundharahotel.com/boss/api/showallreview')
      .then((res) => {
        return res.json();
      })
      .then((data) => {

         console.log(data)
        
         setReviewlist(data.obj);

      });
   }

   useEffect(() => {

      getAllReview();

      getPreloader();
   },[]);
    return (
      <>
      <Head />

      {loading ? 
      <div className="preloader"> 
          <div className="container">
            <div className="dot dot-1"></div>
            <div className="dot dot-2"></div>
            <div className="dot dot-3"></div>
          </div>
      </div>
      :

      <div>
<Header />
<section className="banner" style={{backgroundImage: "url(assets/img/background.png)"}}>
   <div className="container">
      <div className="row align-items-center">
         <div className="col-lg-7">
            <div className="title-area-data">
               <h2>Review</h2>
               <p>A magical combination that sent aromas to the taste buds</p>
            </div>
            {/* <ol className="breadcrumb">
               <li className="breadcrumb-item">
                 <a href="index.html"><i className="fa-solid fa-house"></i> Home</a>
               </li>
               <li className="breadcrumb-item active" aria-current="page">about</li>
            </ol> */}
         </div>
         <div className="col-lg-5">
            <div className="row">
               <div className="col-6">
                  <div className="title-area-img">
                     {/* <img alt="title-area-img" src="assets/img/title-area-img-1.jpg" /> */}
                     {/* <img alt="pata" className="pata" src="assets/img/pata.png" /> */}
                     <img alt="title-area-img" src="assets/img/user_review.png" style={{width: '500px'}}/>
                     
                  </div>
               </div>
               <div className="col-6">
                  <div className="title-area-img two">
                     {/* <img alt="title-area-img" src="assets/img/title-area-img-2.jpg" /> */}
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>

{/* About */}

<ol className="breadcrumb" style={{marginLeft: '10px'}}>
   <li className="breadcrumb-item">
      <Link to="/"><i className="fa-solid fa-house"></i> Home</Link>
   </li>
   {/* <li className="breadcrumb-item active" aria-current="page">Menu</li> */}
   <li className="breadcrumb-item text-white" aria-current="page">Review</li>
</ol>

        
        {/* <h2>About</h2> */}

{/* Meet Our Team */}

<section className="gap no-top">
   <div className="container" style={{textAlign: 'center'}}>
      <div className="heading-two">
         <h2>Our Customer Review</h2>
         <div className="line"></div>
      </div>
      <div className="row text-center">
      
      {reviewlist.map((e)=>
      <div className="col-md-4 mb-4 mb-md-0" key={e.review_id}>
        <div className="card">
          <div className="card-body py-4 mt-2">
            {/* <div className="d-flex justify-content-center mb-4">
              <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp"
                className="rounded-circle shadow-1-strong" width="100" height="100" />
            </div> */}
            <h5 className="font-weight-bold">{e.review_name}</h5>
            {/* <h6 className="font-weight-bold my-3">Founder at ET Company</h6> */}
            {/* <ul className="list-unstyled d-flex justify-content-center">
              <li>
                <i className="fas fa-star fa-sm text-info"></i>
              </li>
              <li>
                <i className="fas fa-star fa-sm text-info"></i>
              </li>
              <li>
                <i className="fas fa-star fa-sm text-info"></i>
              </li>
              <li>
                <i className="fas fa-star fa-sm text-info"></i>
              </li>
              <li>
                <i className="fas fa-star-half-alt fa-sm text-info"></i>
              </li>
            </ul> */}
            <ReactStars
               value={e.review_star}
               style={{textAlign: 'center'}}
               count={5}
               
               size={50}
               edit={false}
               // isHalf={true}
               emptyIcon={<i className="far fa-star"></i>}
               // halfIcon={<i className="fa fa-star-half-alt"></i>}
               fullIcon={<i className="fa fa-star"></i>}
               color="#949191"
               activeColor="#ffd700"
               label="Rating"
               
            />
            <p className="mb-2">
              <i className="fas fa-quote-left pe-2"></i>{e.review_text}
            </p>
            {e.review_image!=null?
            <div className="d-flex justify-content-center mb-4">
                <img src={"https://vasundharahotel.com/boss/storage/app/public/uploads/"+e.review_image} alt="Cropped" width="200" height="200" class="img-fluid rounded-pill" />
            </div>
            :
            ''
            }
            {/* <div className="d-flex justify-content-center mb-4">
              <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp"
                className="rounded-circle shadow-1-strong" width="100" height="100" />
            </div> */}
          </div>
        </div>
      </div>
      )}

      
    </div>

   </div>
</section>


 


        <Footer />

        </div>
}
      </>
    );
  }
  
  export default Review;
  